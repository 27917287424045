import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Dish from "../components/Dish"
import { TEAL } from "../constants/colors"

const Pagetitle = styled.h1`
  text-align: center;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 16px;
  font-size: 36px;
`

const DishesContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 16px;
  max-width: 768px;
  margin: 0 auto 30px;
`

const Contact = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;

  a {
    display: inline-flex;
    align-items: center;
    color: ${TEAL};
    text-decoration: none;
    font-weight: 500;

    i {
      margin: 0 2px 0 6px;
    }
  }
`

const dishes = [
  {
    id: 12,
    title: "rodzinny",
    number: "12",
    price: 99,
    images: ["nr-12.jpg"],
    elements: [
      "5 x chrupiące polędwiczki z kurczaka",
      "2 x gołąbki bez zawijania z sosem",
      "2 x zrazy z karkówki",
      "ziemniaki z wody",
      "surówka",
      "buraczki",
    ],
  },
  {
    id: 1,
    title: "obiadowy",
    number: "1",
    price: 199,
    images: ["nr-1.jpg"],
    elements: [
      "4 x kotlet schabowy",
      "2 x kotlet de’volaille",
      "2 x zrazy z karkówki",
      "1 x grillowana karkówka",
      "ćwiartki ziemniaków",
      "puree z ziemniaków",
      "surówka",
      "kapusta zasmażana",
    ],
  },
  {
    id: 2,
    title: "mięs I",
    number: "2",
    price: 239,
    images: ["nr-2.jpg"],
    elements: [
      "4 x kotlet schabowy",
      "2 x kotlet de’volaille",
      "1 x grillowana karkówka",
      "6 x chrupiące polędwiczki",
      "4 x zrazy z karkówki",
      "2 x filet grillowany",
    ],
  },
  {
    id: 3,
    title: "mięs II",
    number: "3",
    price: 279,
    images: ["nr-3.jpg"],
    elements: [
      "6 x kotlet de’volaille",
      "6 x kotlet schabowy",
      "6 x zrazy z karkówki",
    ],
  },
  {
    id: 4,
    title: "mięs III",
    number: "4",
    price: 369,
    images: ["nr-4.jpg"],
    elements: ["5 x golonka", "10 x karkówka", "kapusta zasmażana"],
  },
  {
    id: 5,
    title: "KEBAB",
    number: "5",
    price: 239,
    images: ["nr-5.jpg"],
    elements: [
      "1 kg mięso kebab z kurczaka",
      "frytki",
      "szyszki",
      "surówka",
      "sos",
    ],
  },
  {
    id: 8,
    title: "chiński",
    number: "8",
    price: 259,
    images: ["nr-8.jpg"],
    elements: [
      "2 x kurczak w kokosie",
      "2 x kurczak w 5 smakach",
      "2 x kurczak pikantny",
      "ryż",
      "kasza",
      "sałatka",
      "surówka",
      "2 x sos",
    ],
  },
  {
    id: 6,
    title: "młodzieżowy",
    number: "6",
    price: 259,
    images: ["nr-6.jpg"],
    elements: [
      "2 x kurczak w kokosie",
      "10 x pikantne skrzydełka",
      "10 x nuggetsy",
      "9 x tortilla",
      "kulki ziemniaczane",
      "nachosy",
      "brokuł w panierce",
      "sos",
    ],
  },
  {
    id: 7,
    title: "drwala",
    number: "7",
    price: 259,
    images: ["nr-7.jpg"],
    elements: [
      "4 x kotlety schabowe",
      "4 x grillowany boczek",
      "2 x zrazy z karkówki",
      "1 x karkówka",
      "1 x strogonow zapiekany ziemniakami",
      "ćwiartki ziemniaków",
      "ziemniaki z wody",
      "kapusta zasmażana",
      "surówka",
    ],
  },

  {
    id: 9,
    title: "Zimnych zakąsek 1",
    number: "9",
    price: 219,
    images: ["nr-11.jpg"],
    elements: [
      "5 x ryba po grecku",
      "5 x szynka po rusku",
      "5 x rolada z kurczaka z serem i papryką",
    ],
  },
  {
    id: 10,
    title: "Zimnych zakąsek 2",
    number: "10",
    price: 219,
    images: ["nr-10.jpg"],
    elements: [
      "5 x schab w galarecie",
      "5 x kurczak w galarecie",
      "5 x pasztet słonecznikowy",
    ],
  },
]

const IndexPage = () => (
  <Layout>
    <SEO title="Gorące Półmiski" />
    <Pagetitle>Gorące Półmiski</Pagetitle>
    <Contact>
      DOSTAWA{" "}
      <a href="tel:662000633">
        <i className="material-icons">call</i> 662 000 633
      </a>
    </Contact>

    <DishesContainer>
      {dishes.map(dish => (
        <Dish key={dish.id} {...dish} />
      ))}
    </DishesContainer>
  </Layout>
)

export default IndexPage
