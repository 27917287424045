import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { TEAL, RED, BORDER_COLOR } from "../constants/colors"
import Image from "./Image"

const Container = styled.div`
  border: 3px solid ${BORDER_COLOR};
  display: flex;
  flex-direction: column;
  position: relative;
`

const DetailsWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const Title = styled.h2`
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.3;
`

const TitleName = styled.span`
  text-transform: uppercase;
  font-weight: bold;
`

const Price = styled.h3`
  font-size: 28px;
  color: ${TEAL};
  margin: 0;
  font-weight: 700;
  margin-left: auto;
  margin-bottom: 5px;
`

const List = styled.ul`
  list-style: none;
  margin-bottom: 0;

  li {
    text-indent: -1.1rem;
    margin-bottom: 0;

    &:before {
      content: "•";
      margin: 0 0.5rem 0 0;
      color: ${RED};
    }
  }
`

const SliderContainer = styled.div`
  .arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    color: white;
    font-size: 40px;
    z-index: 1;
    opacity: 0.75;
  }

  .prev-arrow {
    left: 10px;
  }

  .next-arrow {
    right: 10px;
  }

  .slick-dots {
    bottom: 10px;

    li button:before {
      font-size: 10px;
      color: white;
    }
  }

  img {
    margin-bottom: 0;
  }
`

const PrevArrow = props => {
  const { style, onClick } = props
  return (
    <div
      className="arrow prev-arrow material-icons"
      style={{ ...style }}
      onClick={onClick}
    >
      keyboard_arrow_left
    </div>
  )
}

const NextArrow = props => {
  const { style, onClick } = props
  return (
    <div
      className="arrow next-arrow material-icons"
      style={{ ...style }}
      onClick={onClick}
    >
      keyboard_arrow_right
    </div>
  )
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
}

const Dish = ({ id, title, price, number, images, elements }) => (
  <Container>
    <SliderContainer>
      <Slider {...settings}>
        {images.map(image => (
          <Image key={image} src={image} />
        ))}
      </Slider>
    </SliderContainer>
    <DetailsWrapper>
      <Price>{price} zł</Price>
      <Title>
        {id === 9 || id === 10 ? (
          <>
            MIX
            <br />
          </>
        ) : (
          "Gorący półmisek"
        )}{" "}
        <TitleName>{title}</TitleName>{" "}
        {id === 9 || id === 10 ? (
          <>
            <br />
            /dwa platery/
          </>
        ) : (
          ""
        )}
      </Title>
      <List>
        {elements.map(element => (
          <li key={element}>{element}</li>
        ))}
      </List>
    </DetailsWrapper>
  </Container>
)

export default Dish
